import { types } from "../types/types";

const initialState = {
  cursos: [],
  cursoActivo: {}
};

export const cursoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.crearCursoOk:
      return {
        ...state,
        cursos:[ ...state.cursos, action.payload ]
      }
    case types.obtenerCursosOk:
      return {
        ...state,
        cursos: action.payload
      }
    case types.actualizarCursoOk:
      return {
        ...state,
        cursos: state.cursos.map(
            e => (e._id === action.payload._id) ? action.payload : e
        )
      }
    case types.eliminarCursoOk:
      return {
        ...state,
        cursos: state.cursos.filter(
          e => (e._id !== action.payload._id)
        )

      }
    case types.activarCurso:
      return {
        ...state,
        cursoActivo: action.payload
      }
    default:
      return state;
  }
};