export const types = {

    openCursos: '[Admin] open cursos',
    openEstudiantes: '[Admin] open estudiantes',
    openInasistencias: '[Admin] open inasistencias',
    openFichaInasistencia: '[Admin] open ficha inasistencia',
    openFormActualizarCurso: '[Admin] open form actualizar curso',
    openActualizarEstudiante: '[Admin] open actualizar estudiante',

    openFormCrearEstudiante: '[Index] openFormCrearEstudiante',
    openFormCrearApoderado: '[Index] openFormCrearApoderado',
    openFormCrearInasistencia: '[Index] openFormCrearInasistencia',


    authStartLogin: '[auth] Start login',
    authCheckingFinish: '[auth] checking finish',
    authLogin: '[auth] authLogin',
    authLogout: '[auth] Logout',

    //Curso
    crearCursoOk:'[Curso] crear ok',
    crearCursoError:'[Curso] crear error',
    obtenerCursosOk : '[Curso] obtener ok',
    obtenerCursosError: '[Curso] obtener error',
    actualizarCursoOk : '[Curso] actualizar ok',
    actualizarCursoError: '[Curso] actualizar error',
    eliminarCursoOk : '[Curso] eliminar ok',
    eliminarCursoError: '[Curso] eliminar error',
    activarCurso: '[Curso] activo',
    
    crearUsuarioOk: '[Usuario] crear ok',
    crearUsuarioError: '[Usuario] crear error',
    obtenerUsuariosOk : '[Usuario] obtener ok',
    obtenerUsuariosError: '[Usuario] obtener error',
    actualizauthStartLoginarUsuarioOk : '[Usuario] actualizar ok',
    actualizarUsuarioError: '[Usuario] actualizar error',

    crearEstudianteOk: '[Estudiante] crear ok',
    crearEstudianteError: '[Estudiante] crear error',
    obtenerEstudiantesOk : '[Estudiante] obtener ok',
    obtenerEstudiantesError: '[Estudiante] obtener error',
    actualizarEstudianteOk : '[Estudiante] actualizar ok',
    actualizarEstudianteError: '[Estudiante] actualizar error',
    eliminarEstudianteOk : '[Estudiante] eliminar ok',
    eliminarEstudianteError : '[Estudiante] eliminar error',
    activarEstudiante: '[Estudiante] activo',

    crearApoderadoOk: '[Apoderado] crear ok',
    crearApoderadoError: '[Apoderado] crear error',
    obtenerApoderadosOk : '[Apoderado] obtener ok',
    obtenerApoderadosError: '[Apoderado] obtener error',
    actualizarApoderadoOk : '[Apoderado] actualizar ok',
    actualizarApoderadoError: '[Apoderado] actualizar error',
    activarApoderado: '[Apoderado] activo',

    crearInasistenciaOk: '[Inasistencia] crear ok',
    crearInasistenciaError: '[Inasistencia] crear error',
    obtenerInasistenciasOk : '[Inasistencia] obtener ok',
    obtenerInasistenciasError: '[Inasistencia] obtener error',
    actualizarInasistenciaOk : '[Inasistencia] actualizar ok',
    actualizarInasistenciaError: '[Inasistencia] actualizar error',
    activarInasistencia: '[Inasistencia] activa',

    startUploadingOk: '[File] subida de doc ok'


};