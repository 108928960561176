import React from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { store } from '../state/createStore'
import { Navbar } from './navbar'
import fondo from '../images/salem.gif'
import '../style/style.scss'


const Layout = ({children}) => {
    return (
        <Provider store={store} >
            <Helmet>
                <meta charset="utf-8" />
                <link rel="icon" />
                <meta http-equiv='Content-Language' content='es' />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="theme-color" content="#fffff" />
                <meta name="description" content="Inasistencias colegio cielos del valle" />
                {/* <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" /> */}
                <title>Inasistencia ...:::Colegio Cielos del Valle:::...</title>
                <link 
                    href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css" 
                    rel="stylesheet" 
                    integrity="sha384-F3w7mX95PdgyTmZZMECAngseQB83DfGTowi0iMjiWaeVhAn4FJkqJByhZMI3AhiU" 
                    crossOrigin="anonymous"
                />
                <script src="https://kit.fontawesome.com/b4a2746c93.js" crossorigin="anonymous"></script>
            </Helmet>
            <main style={{
                backgroundImage:`url(${fondo})`, 
                backgroundRepeat:'repeat', 
                backgroundSize:'180px', 
                backgroundPosition:'fixed',
                height:'100%',
                minHeight:'100vh'
            }}>
                <Navbar />
                {children}
            </main>
            <link
                src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.bundle.min.js" 
                integrity="sha384-/bQdsTh/da6pkI1MST/rWKFNjaCP5gBSY4sEBT38Q/9RBh9AH40zEOg7Hlq2THRZ" 
                crossOrigin="anonymous"
            />
        </Provider>
    )
}

export default Layout
