const baseUrl = 'http://134.209.170.151:5000/microservicio/v1/justificainasistencia';

const fetchSinToken = ( endpoint, data, method='GET' ) => {

    const url = `${ baseUrl }/${ endpoint}`;

    if( method === 'GET' ) {
        return fetch( url );
    } else {
        return fetch(url, {
            method,
            headers:{
                'Content-type': 'application/json'
            },
            body:JSON.stringify( data )
        });
    }
};

const fetchConToken = ( endpoint, data, method='GET' ) => {

    const url = `${ baseUrl }/${ endpoint}`;
    const token = localStorage.getItem('token') || '';

    if( method === 'GET' ) {
        return fetch( url, {
            method,
            headers: {
                'x-auth-token': token

            }
        } );
    } else {
        return fetch(url, {
            method,
            headers:{
                'Content-type': 'application/json',
                'x-auth-token': token
            },
            body:JSON.stringify( data )
        });
    }


};

export {
    fetchSinToken,
    fetchConToken
};