import { types } from "../types/types";

const initialState = {
  estudiantes:[],
  estudianteActivo:{}
};

export const estudianteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.crearEstudianteOk:
      return {
        ...state,
        estudiantes:[ ...state.estudiantes, action.payload ]
      }
    case types.obtenerEstudiantesOk:
      return {
        ...state,
        estudiantes: action.payload
      }
    case types.actualizarEstudianteOk:
      return {
        ...state,
        estudiantes: state.estudiantes.map(
            e => (e._id === action.payload._id) ? action.payload : e
        )
      }
      case types.activarEstudiante:
        return {
          ...state,
          estudianteActivo: action.payload
        }
      case types.eliminarEstudianteOk:
        return {
          ...state,
          estudiantes: state.estudiantes.filter(
            e => ( e._id !== action.payload._id )
        ),
        }
    default:
      return state;
  }
};