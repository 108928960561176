import { types } from "../types/types";

const initialState = {
  cursosScreen: false,
  inasistenciasScreen: true,
  estudiantesScreen: false,
  fichaInasistenciaScreen: false,
  formActualizarCurso: false,
  actualizarEstudiante: false,
  formCrearInasistencia: false,
  formCrearEstudiante: true,
  formCrearApoderado: false,
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.openCursos:
      return {
        ...state,
        cursosScreen: true,
        inasistenciasScreen: false,
        estudiantesScreen: false,
        fichaInasistenciaScreen:false,
      }
    case types.openEstudiantes:
      return {
        ...state,
        cursosScreen: false,
        inasistenciasScreen: false,
        estudiantesScreen: true,
        fichaInasistenciaScreen:false,
        actualizarEstudiante:false,
      }
    case types.openInasistencias:
      return {
        ...state,
        cursosScreen: false,
        inasistenciasScreen: true,
        estudiantesScreen: false,
        fichaInasistenciaScreen:false,
      }
    case types.openFichaInasistencia:
      return {
        ...state,
        inasistenciasScreen:false,
        fichaInasistenciaScreen:true,
      }
    case types.openFormActualizarCurso:
      return {
        ...state,
        formActualizarCurso: action.payload
      }
    case types.openActualizarEstudiante: 
      return {
        ...state,
        actualizarEstudiante:true,
        estudiantesScreen: false,
      }
    case types.openFormCrearEstudiante:
      return {
        ...state,
        formCrearInasistencia: false,
        formCrearEstudiante: true,
        formCrearApoderado: false,
      }
    case types.openFormCrearApoderado:
      return {
        ...state,
        formCrearInasistencia: false,
        formCrearEstudiante: false,
        formCrearApoderado: true,
      }
    case types.openFormCrearInasistencia:
      return {
        ...state,
        formCrearInasistencia: true,
        formCrearEstudiante: false,
        formCrearApoderado: false,
      }
    default:
      return state;
  }
};