import { types } from "../types/types";

const initialState = {
  apoderados:[],
  apoderadoActivo:{}
};

export const apoderadoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.crearApoderadoOk:
      return {
        ...state,
        apoderados:[ ...state.apoderados, action.payload ]
      }
    case types.obtenerApoderadosOk:
      return {
        ...state,
        apoderados: action.payload
      }
    case types.actualizarApoderadoOk:
      return {
        ...state,
        apoderados: state.apoderados.map(
            e => (e._id === action.payload._id) ? action.payload : e
        )
      }
      case types.activarApoderado:
      return {
        ...state,
        apoderadoActivo: action.payload
      }
    default:
      return state;
  }
};