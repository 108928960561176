import { types } from "../types/types";

const initialState = {
  inasistencias: [],
  inasistenciaActiva: {},
  doc:'',
};

export const inasistenciaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.crearInasistenciaOk:
      return {
        ...state,
        inasistencias:[ ...state.inasistencias, action.payload ],
        doc:''
      }
    case types.obtenerInasistenciasOk:
      return {
        ...state,
        inasistencias: action.payload
      }
    case types.actualizarInasistenciaOk:
      return {
        ...state,
        inasistencias: state.inasistencias.map(
            e => (e._id === action.payload._id) ? action.payload : e
        )
      }
    case types.activarInasistencia:
      return {
        ...state,
        inasistenciaActiva: action.payload
      }
    case types.startUploadingOk:
      return {
        ...state,
        doc: action.payload
      }
    default:
      return state;
  }
};