import { combineReducers } from 'redux';
import { adminReducer } from './adminReducer';
import { apoderadoReducer } from './apoderadoReducer';
import { authReducer } from './authReducer';
import { cursoReducer } from './cursoReducer';
import { estudianteReducer } from './estudianteReducer';
import { inasistenciaReducer } from './inasistenciaReducer';

export const rootReducer = combineReducers({
    admin: adminReducer,
    curso: cursoReducer,
    estudiante: estudianteReducer,
    inasistencia: inasistenciaReducer,
    apoderado: apoderadoReducer,
    auth: authReducer
});